import React from "react";
import { Button } from "@heroui/react";
import { Modal, ModalContent, ModalBody, ModalHeader, ModalFooter } from "@heroui/modal";
import { useAcknowledgeAlert, useAlertMessage } from "../../Context/Main";

const Alert: React.FC = () => {
  const alertMessage = useAlertMessage();
  const acknowledgeAlert = useAcknowledgeAlert();
  return (
    <Modal isOpen={alertMessage != undefined} onClose={acknowledgeAlert} hideCloseButton isDismissable={false}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">Oeps...</ModalHeader>
            <ModalBody>
              <p>{alertMessage}</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onPress={onClose}>
                Begrepen
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default Alert;
