import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HeroUIProvider } from "@heroui/react";
import "./utils/sentry.js";

// Components
import ErrorBoundary from "./Components/ErrorBoundary.js";
import App from "./App/App";
// Styles
import "./index.css";
import { AuthProvider } from "./Context/Auth.js";
import { IconProvider } from "./Context/Icons.js";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <HeroUIProvider>
          <IconProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </IconProvider>
        </HeroUIProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);
