import { Alert, Button } from "@heroui/react";
import Lottie from "react-lottie-player";
import { group } from "../../../../assets/lottie";

const NoGroup: React.FC<{ onCreateNewGroup: () => void }> = (props) => {
  return (
    <>
      <Lottie loop animationData={group} play speed={0.1} segments={[34, 120]} style={{ maxHeight: 500, height: "60%" }} />
      <div className="mb-4">
        <Alert
          color="warning"
          variant="bordered"
          description={"Maak een nieuwe groep aan of voeg jezelf toe aan een bestaande groep."}
          title={"Je bent nog geen lid van een groep"}
        />
      </div>
      <Button data-cy="noGroupCreateGroupButton" color="primary" onPress={props.onCreateNewGroup}>
        Nieuwe groep aanmaken
      </Button>
    </>
  );
};

export default NoGroup;
