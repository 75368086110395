import { Input, Checkbox } from "@heroui/react";
import { Modal, ModalContent, ModalBody } from "@heroui/modal";
import { useEffect, useState } from "react";
import Button from "../../../../../Components/layout/Button";
import { startDrawing } from "../../../../../utils/group";
import {
  useCurrentGroup,
  useRefreshCurrentGroup,
} from "../../../../../Context/CurrentGroup";

type Props = { close: () => void };

const StartDrawing: React.FC<Props> = (props) => {
  const currentGroup = useCurrentGroup();
  const refreshCurrentGroup = useRefreshCurrentGroup();
  // States
  const [time, setTime] = useState("2");
  const [self, setSelf] = useState(false);
  const [validInput, setValidInput] = useState(false);

  // Effects
  useEffect(() => {
    setValidInput(
      Number(time) > 0 && Number(time) < 11 && Number.isInteger(Number(time))
    );
  }, [time]);
  // Functions

  const handleStartDrawing = async () => {
    if (!currentGroup) return;
    const seconds = Number(time) * 60;
    await startDrawing(currentGroup.id, seconds, self);
    refreshCurrentGroup && refreshCurrentGroup();
    props.close();
  };

  // Component
  return (
    <Modal
      size="4xl"
      isOpen={true}
      isDismissable={false}
      onClose={props.close}
      classNames={{ body: "flex flex-row m-0 p-0" }}
    >
      <ModalContent>
        <ModalBody className="m-5 flex-col">
          <h1 className="text-center">Alright, koffietijd!</h1>
          <Input
            type="number"
            inputMode="numeric"
            label="Aantal minuten voordat ronde begint"
            labelPlacement="outside"
            value={time}
            onValueChange={setTime}
            min={1}
            max={10}
          />
          <Checkbox isSelected={self} color="primary" onValueChange={setSelf}>
            Ik haal zelf koffie!
          </Checkbox>
          <Button onClick={handleStartDrawing} isDisabled={!validInput}>
            Start ronde
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default StartDrawing;
