import { Input } from "@heroui/react";
import { useEffect, useState } from "react";
import {
  useCurrentGroup,
  useRefreshCurrentGroup,
} from "../../../../../../Context/CurrentGroup";
import { updateGroupFunction } from "../../../../../../utils/group";
import { useGetProfileFunction } from "../../../../../../Context/Profile";

type Props = {
  updateValide: (valide: boolean) => void;
  confirmDispatched: boolean;
  resetConfirmDispatched: () => void;
};
const Group: React.FC<Props> = (props) => {
  const currentGroup = useCurrentGroup();
  const refreshCurrentGroup = useRefreshCurrentGroup();
  const getProfileFunction = useGetProfileFunction();

  const originalName = currentGroup?.name || "";
  const [name, setName] = useState(originalName);

  useEffect(() => {
    const r = /^[a-z\s]+$/gi;
    const v =
      name.length > 2 &&
      r.test(name) &&
      name.length < 20 &&
      name != originalName;
    props.updateValide(v);
  }, [name]);

  useEffect(() => {
    if (props.confirmDispatched) handleUpdate();
  }, [props.confirmDispatched]);

  // Functions
  const handleUpdate = async () => {
    if (!currentGroup) return;
    await updateGroupFunction(currentGroup.id, name);
    refreshCurrentGroup && refreshCurrentGroup();
    getProfileFunction && getProfileFunction();
    props.resetConfirmDispatched();
  };

  return (
    <div className="grid gap-6">
      <Input
        type="text"
        label="Naam"
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
    </div>
  );
};

export default Group;
