import { Button, Tooltip } from "@heroui/react";
import { Fragment, useEffect, useState } from "react";
import NewMemberModal from "./NewMember";
import RemoveMemberModal from "./RemoveMember";
import {
  useCurrentGroup,
  useRefreshCurrentGroup,
} from "../../../../../../Context/CurrentGroup";
import {
  addMemberFunction,
  changeMemberOwner,
  removeMemberFunction,
} from "../../../../../../utils/group";
import { useProfile } from "../../../../../../Context/Profile";
import { BiCrown, BiTrash } from "react-icons/bi";

const Members: React.FC = () => {
  const currentGroup = useCurrentGroup();
  const refreshCurrentGroup = useRefreshCurrentGroup();
  const profile = useProfile();

  // States
  const [userToRemove, setUserToRemove] = useState<string | undefined>(
    undefined
  );
  const [inviteToRemove, setInviteToRemove] = useState<string | undefined>(
    undefined
  );
  const [nameOfToRemove, setNameOfToRemove] = useState<string | undefined>(
    undefined
  );
  // const validInput = useValidator([{ value: email, isEmail: true }]);
  const [addingNewMember, setAddingNewMember] = useState(false);
  const [addingNewMemberMustInvite, setAddingNewMemberMustInvite] =
    useState(false);

  // Effects
  useEffect(() => {
    if (!currentGroup || (!userToRemove && !inviteToRemove))
      return setNameOfToRemove(undefined);
    if (userToRemove) {
      const name = currentGroup.members.find((m) => m.user.id == userToRemove)
        ?.user.name;
      if (name) return setNameOfToRemove(name);
    }
    if (inviteToRemove) {
      const email = currentGroup.invites.find(
        (i) => i.id == inviteToRemove
      )?.email;
      if (email) return setNameOfToRemove(email);
    }
    return setNameOfToRemove(undefined);
  }, [userToRemove, inviteToRemove]);

  // Functions
  const handleAddUser = async (email: string, invite: boolean) => {
    if (!currentGroup) return;
    const userAdded = await addMemberFunction(currentGroup?.id, email, invite);
    if (userAdded) {
      refreshCurrentGroup && refreshCurrentGroup();
      closeAddMemberModal();
    } else {
      setAddingNewMemberMustInvite(true);
    }
  };

  const handleRemoveUser = async () => {
    if (!currentGroup) return;
    if (userToRemove)
      await removeMemberFunction(currentGroup.id, userToRemove, false);
    if (inviteToRemove)
      await removeMemberFunction(currentGroup.id, inviteToRemove, true);
    setUserToRemove(undefined);
    setInviteToRemove(undefined);
    refreshCurrentGroup && refreshCurrentGroup();
  };

  const handleChangeMemberOwner = async (userId: string, isOwner: boolean) => {
    if (!currentGroup) return;
    await changeMemberOwner(currentGroup.id, userId, isOwner);
    refreshCurrentGroup && refreshCurrentGroup();
  };

  const closeAddMemberModal = () => {
    setAddingNewMember(false);
    setAddingNewMemberMustInvite(false);
  };

  return (
    <>
      {addingNewMember && (
        <NewMemberModal
          onClose={closeAddMemberModal}
          onConfirm={handleAddUser}
          mustInvite={addingNewMemberMustInvite}
        />
      )}
      {nameOfToRemove && (
        <RemoveMemberModal
          onClose={() => {
            setUserToRemove(undefined);
            setInviteToRemove(undefined);
          }}
          onConfirm={handleRemoveUser}
          name={nameOfToRemove}
        />
      )}
      <div className="flex flex-col gap-3 mt-3">
        {currentGroup?.members
          .filter((m) => m.user.id != profile?.id)
          .map((member) => {
            const classNameForOwnerIcon = member.owner
              ? "cursor-pointer hover:scale-105"
              : "grayscale-[80%] hover:grayscale-0 cursor-pointer hover:scale-105";
            const textForOwnerTooltip = member.owner
              ? "Verwijder groepsbeheerrechten"
              : "Geef groepsbeheerrechten";
            return (
              <Fragment key={member.user.id}>
                <div className="flex gap-1 items-center">
                  <span className="flex-1">
                    {member.user.name || member.user.email}
                  </span>
                  <BiCrown
                    className={classNameForOwnerIcon}
                    size="1.4em"
                    onClick={() =>
                      handleChangeMemberOwner(member.user.id, !member.owner)
                    }
                  />
                  <Tooltip content={textForOwnerTooltip}></Tooltip>
                  <Tooltip content="Verwijder gebruiker">
                    <BiTrash
                      className="cursor-pointer hover:scale-105 text-danger-LIGHT hover:text-danger"
                      size="1.4em"
                      onClick={() => setUserToRemove(member.user.id)}
                    />
                  </Tooltip>
                </div>
              </Fragment>
            );
          })}
        {currentGroup?.invites.map((invite) => {
          return (
            <Fragment key={invite.id}>
              <div className="flex gap-1 items-center">
                <span className="flex-1">{invite.email}</span>
                <span className="text-danger-400">Nog niet geaccepteerd</span>
                <Tooltip content="Verwijder uitnodiging">
                  <BiTrash
                    className="cursor-pointer hover:scale-105 text-danger-LIGHT hover:text-danger"
                    size="1.4em"
                    onClick={() => setInviteToRemove(invite.id)}
                  />
                </Tooltip>
              </div>
            </Fragment>
          );
        })}
        <Button
          data-cy="membersAddButton"
          color="primary"
          fullWidth
          onPress={() => setAddingNewMember(true)}
          isDisabled={false}
        >
          Nieuw lid toevoegen
        </Button>
      </div>
    </>
  );
};

export default Members;
