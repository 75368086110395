import * as Api from "../api-sdk";
import { getAccessToken } from "./cognito";
import { captureEvent, captureException } from "@sentry/react";

const configuration = new Api.Configuration({
  basePath: import.meta.env.VITE_API_PATH,
});

const getToken = async () => {
  const accessToken = await getAccessToken();
  return accessToken;
};

type CreateGroupFunction = (name: string) => Promise<string | void>;
export const createGroupFunction: CreateGroupFunction = async (
  name: string
) => {
  try {
    const accessToken = await getToken();
    if (!accessToken) throw new Error("Failed to get access token");
    const groupApi = new Api.GroupsApi(configuration);
    const { id } = await groupApi.createGroup({
      accessToken,
      groupBody: { name },
    });
    return id;
  } catch (e) {
    if (e instanceof Api.ResponseError) {
      if (e.response.status == 403) {
      } else {
        captureEvent(e);
      }
      // return setAlertMessage("Je mag maximaal 5 groepen aanmaken.");
    }
  }
};

export const updateGroupFunction = async (id: string, name: string) => {
  try {
    const accessToken = await getToken();
    if (!accessToken) return;
    const groupApi = new Api.GroupsApi(configuration);
    await groupApi.updateGroup({
      accessToken,
      id,
      groupBody: { name },
    });
  } catch (e) {
    captureException(e);
  }
};

export const deleteGroupFunction = async (id: string) => {
  try {
    const accessToken = await getToken();
    if (!accessToken) return;
    const api = new Api.GroupsApi(configuration);
    await api.deleteGroup({ accessToken, id });
  } catch (e) {
    captureException(e);
  }
};
export const createDrinkFunction = async (
  id: string,
  name: string,
  icon: string,
  extras: string
) => {
  try {
    const accessToken = await getToken();
    if (!accessToken) return;
    const addDrinkBody: Api.AddDrinkBody = {
      name,
      icon,
      extras,
    };
    const api = new Api.GroupDrinksApi(configuration);
    await api.addDrink({
      accessToken,
      id,
      addDrinkBody,
    });
  } catch (e) {
    captureException(e);
  }
};
export const updateDrinkFunction = async (
  id: string,
  drinkId: string,
  name: string,
  icon: string,
  extras: string
) => {
  try {
    const accessToken = await getToken();
    if (!accessToken) return;
    const addDrinkBody: Api.AddDrinkBody = {
      name,
      icon,
      extras,
    };
    const api = new Api.GroupDrinksApi(configuration);
    await api.updateDrink({
      accessToken,
      id,
      drinkId,
      addDrinkBody,
    });
  } catch (e) {
    captureException(e);
  }
};
export const deleteDrinkFunction = async (id: string, drinkId: string) => {
  try {
    const accessToken = await getToken();
    if (!accessToken) return;
    const api = new Api.GroupDrinksApi(configuration);
    const response = await api.deleteDrinkRaw({
      accessToken,
      id,
      drinkId,
    });
    if (response.raw.status === 204) {
      return true;
    }
  } catch (e) {
    captureException(e);
  } finally {
    return false;
  }
};
export const placeOrderFunction = async (
  id: string,
  drawingId: string,
  drink: string,
  text?: string,
  extras?: string
) => {
  try {
    const accessToken = await getToken();
    if (!accessToken) return;
    const api = new Api.GroupDrawingsApi(configuration);
    await api.placeOrder({
      accessToken,
      id,
      drawingId,
      placeOrderBody: { drink, text, extras },
    });
  } catch (e) {
    captureException(e);
  }
};
export const addMemberFunction = async (
  id: string,
  email: string,
  invite: boolean
) => {
  const accessToken = await getToken();
  if (!accessToken) return;
  const api = new Api.GroupMembersApi(configuration);
  try {
    await api.addMember({
      accessToken,
      id,
      addMemberBody: { email, invite },
    });

    return true;
  } catch (e) {
    return false;
  }
};

export const changeMemberOwner = async (
  id: string,
  userId: string,
  isOwner: boolean
) => {
  try {
    const accessToken = await getToken();
    if (!accessToken) return;
    const api = new Api.GroupMembersApi(configuration);
    await api.updateMember({
      accessToken,
      id,
      userId,
      updateMemberBody: { isOwner },
    });
  } catch (e) {
    captureException(e);
  }
};

export const removeMemberFunction = async (
  groupId: string,
  id: string,
  isInvite: boolean
) => {
  try {
    const accessToken = await getToken();
    if (!accessToken) return;
    const api = new Api.GroupMembersApi(configuration);
    if (isInvite) {
      await api.deleteInvite({
        accessToken,
        id: groupId,
        inviteId: id,
      });
    } else {
      await api.deleteMember({
        accessToken,
        id: groupId,
        userId: id,
      });
    }
  } catch (e) {
    captureException(e);
  }
};

export const toggleNotificationFunction = async (
  groupId: string,
  method: string,
  value: boolean
) => {
  try {
    const accessToken = await getToken();
    if (!accessToken) return;
    const groupNotificationApi = new Api.GroupNotificationsApi(configuration);
    await groupNotificationApi.updateNotifcation({
      accessToken,
      id: groupId,
      notificationBody: { method, value },
    });
  } catch (e) {
    captureException(e);
  }
};

export const startDrawing = async (
  id: string,
  seconds: number,
  self: boolean
) => {
  try {
    const accessToken = await getToken();
    if (!accessToken) return;
    const api = new Api.GroupDrawingsApi(configuration);
    await api.startDrawing({
      accessToken,
      id,
      startDrawingBody: { seconds, self },
    });
  } catch (e) {
    captureException(e);
  }
};
