import { Switch, Button } from "@heroui/react";
import { useState } from "react";
import { deleteGroupFunction } from "../../../../../../utils/group";
import { useCurrentGroup } from "../../../../../../Context/CurrentGroup";
import { useGetProfileFunction } from "../../../../../../Context/Profile";

type Props = { onClose: () => void };

const RemoveGroupBlade: React.FC<Props> = (props) => {
  const [confirm, setConfirm] = useState(false);
  const getProfileFunction = useGetProfileFunction();
  const currentGroup = useCurrentGroup();

  const handleDeleteGroup = async () => {
    if (!currentGroup) return;
    await deleteGroupFunction(currentGroup.id);
    // Refresh group
    getProfileFunction && (await getProfileFunction());
    props.onClose();
  };

  if (currentGroup?.active) {
    return (
      <div className="grid gap-6">
        <p>
          Als je deze groep verwijdert dan is de groep nog zichtbaar bij
          gebruikers totdat ze opnieuw hebben ingelogd. Eventuele lopende rondes
          worden nog afgemaakt.
        </p>
        <Switch
          data-cy="removeGroupSwitch"
          isSelected={confirm}
          onValueChange={setConfirm}
          color="danger"
        >
          Ik weet zeker dat ik de groep{" "}
          <span className="font-semibold">{currentGroup.name}</span> wil
          verwijderen
        </Switch>
        <Button
          data-cy="removeGroupConfirm"
          color="danger"
          variant="bordered"
          isDisabled={!confirm}
          onPress={handleDeleteGroup}
        >
          Groep verwijderen
        </Button>
      </div>
    );
  } else {
    return (
      <>
        <p className="font-semibold">Groep verwijderen</p>
        <hr />
        <div className="grid gap-6">
          <p>
            Deze groep is verwijderd. De volgende keer als je inlogd zal de
            groep niet meer zichtbaar zijn
          </p>
        </div>
      </>
    );
  }
};

export default RemoveGroupBlade;
