import { useCallback, useState } from "react";
import { useContextSelector, createContext } from "use-context-selector";

const useStore = () => {
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );

  return {
    alertMessage,
    setAlertMessage,
    acknowledgeAlert: useCallback(() => setAlertMessage(undefined), []),
  };
};

// Context
interface IMainContext {
  alertMessage?: string;
  setAlertMessage: (message: string) => void;
  acknowledgeAlert: () => void;
}

export const MainContext = createContext<IMainContext | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

export const MainProvider = (props: Props) => (
  <MainContext.Provider value={useStore()}>
    {props.children}
  </MainContext.Provider>
);

export const useAlertMessage = () =>
  useContextSelector(MainContext, (s) => s?.alertMessage);
export const useSetAlertMessage = () =>
  useContextSelector(MainContext, (s) => s?.setAlertMessage);
export const useAcknowledgeAlert = () =>
  useContextSelector(MainContext, (s) => s?.acknowledgeAlert);
