import { Alert, Button, CircularProgress } from "@heroui/react";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import { coffeeMachine } from "../../../../assets/lottie";
import { useEffect, useState } from "react";
import { useRefreshCurrentGroup } from "../../../../Context/CurrentGroup";
type Props = {
  self: boolean;
  startedBy: string;
  originalSeconds: number;
  seconds: number;
  placedOrder: boolean;
  handlePlaceOrder: () => void;
};

const ActiveDrawing: React.FC<Props> = (props) => {
  const [remainingTime, setRemainingTime] = useState<number>(props.seconds);
  const refreshCurrentGroup = useRefreshCurrentGroup();

  const fromTime = Math.round(Date.now() / 1000);
  const minutes =
    remainingTime < 0
      ? "00"
      : Math.floor(remainingTime / 60)
          .toString()
          .padStart(2, "0");
  const seconds =
    remainingTime < 0 ? "00" : (remainingTime % 60).toString().padStart(2, "0");

  useEffect(() => {
    // Countdown
    const interval = setInterval(() => {
      const now = Math.round(Date.now() / 1000);
      const remainingTime = props.seconds - (now - fromTime);
      setRemainingTime(remainingTime);
      if (
        remainingTime == -5 ||
        (remainingTime < 0 && remainingTime % -15 == 0)
      ) {
        refreshCurrentGroup && refreshCurrentGroup();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="flex flex-col items-center">
        <CircularProgress
          color="primary"
          formatOptions={{ style: "percent" }}
          label={`${minutes}:${seconds}`}
          showValueLabel={false}
          size="lg"
          value={(remainingTime / props.originalSeconds) * 100}
          classNames={{ label: "text-xl" }}
        />
      </div>

      <Lottie
        loop
        animationData={coffeeMachine}
        play
        speed={0.1}
        segments={[34, 120]}
        style={{ maxHeight: 500, height: "60%" }}
      />
      {props.self && (
        <div className="mb-4">
          <Alert
            color="success"
            title={props.startedBy + " haalt koffie voor jullie!"}
          />
        </div>
      )}
      {!props.self && (
        <div className="mb-4">
          <Alert color="primary" title={"Er vindt een loting plaats"} />
        </div>
      )}

      {remainingTime > 0 && (
        <Button
          fullWidth
          color="primary"
          isDisabled={props.placedOrder}
          variant="solid"
          onPress={props.handlePlaceOrder}
        >
          Bestelling doorgeven
        </Button>
      )}
      {remainingTime <= 0 && (
        <Button
          fullWidth
          color="danger"
          variant="bordered"
          onPress={() => refreshCurrentGroup && refreshCurrentGroup()}
        >
          Uitslag niet zichtbaar? Klik hier om te refreshen
        </Button>
      )}
    </>
  );
};

export default ActiveDrawing;
