import { Alert, Button } from "@heroui/react";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import { makingCoffee } from "../../../../assets/lottie";

const Result: React.FC<{
  who: string;
  self: boolean;
  startDrawing: () => void;
  showOrderDetails: () => void;
}> = (props) => {
  return (
    <>
      <div>
        {props.self ? (
          <Alert
            color="primary"
            title="Heb jij even pech!"
            description="Jij bent aan de beurt. We hebben je een e-mail gestuurd met de bestelling!"
          />
        ) : (
          <Alert variant="bordered" color="success" title="Heb jij even geluk!" description={`Koffie wordt gehaald door ${props.who}`} />
        )}
      </div>
      <Lottie loop animationData={makingCoffee} play speed={0.1} segments={[34, 120]} style={{ maxHeight: 500, height: "60%" }} />
      <div className="flex flex-col gap-2">
        <Button fullWidth color="primary" variant="solid" onPress={props.showOrderDetails}>
          Bekijk bestelling
        </Button>
        <Button fullWidth color="primary" variant="bordered" onPress={props.startDrawing}>
          Nieuwe koffieronde starten
        </Button>
      </div>
    </>
  );
};

export default Result;
