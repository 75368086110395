import { Avatar, Button } from "@heroui/react";
import { Modal, ModalContent, ModalBody } from "@heroui/modal";
import { Order } from "../../../../../api-sdk/";
import { useCurrentGroup } from "../../../../../Context/CurrentGroup";
const avatarUrl = import.meta.env.VITE_AVATAR_URL;

type OrderRowProps = { order: Order };

const OrderRow: React.FC<OrderRowProps> = (props) => {
  return (
    <div className="flex gap-2">
      <Avatar src={avatarUrl + props.order.user.avatar} />
      <p>
        {props.order.user.name!} - {props.order.drink}
        <br />
        {props.order.extras}
        {props.order.extras && props.order.text && " - "}
        {props.order.text}
      </p>
    </div>
  );
};

type Props = { close: () => void };

const OrderDetails: React.FC<Props> = (props) => {
  const currentGroup = useCurrentGroup();
  const lastDrawing = currentGroup?.lastDrawing;

  // If no last drawing / orders, don't show modal
  if (!lastDrawing || lastDrawing.orders.length < 1) {
    props.close();
    return undefined;
  }
  return (
    <Modal size="4xl" isDismissable={false} isOpen={true} onClose={props.close} classNames={{ body: "flex" }}>
      <ModalContent>
        {() => (
          <>
            <ModalBody className="text-secondary-950">
              <h2 className="font-semibold">Bestelling laatste ronde</h2>
              <hr />
              {lastDrawing.orders.map((order) => (
                <OrderRow order={order} />
              ))}
              <Button color="primary" onPress={props.close}>
                Oké
              </Button>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default OrderDetails;
